import React from "react";
import Lottie from "lottie-react";
import astridAnimation from "../../assets/animations/astrid.json"

import "./LoadingPage.css";


function LoadingPage() {
  return (
    <div className="loading-page">
    <Lottie
      className="loading-page__spinner"
      animationData={astridAnimation}
      loop={true}
    />
        </div>
  );
}

export default LoadingPage;

import React, { Suspense, useEffect, lazy } from "react";

const MainPage = lazy(() => import("./components/MainPage"));

import LoadingPage from "./components/Loading/LoadingPage";

import "./App.css";


function App() {
  return (
    <div className="App">
      <Suspense fallback={<LoadingPage />}>
      <MainPage />
      </Suspense>
    </div>
  );
}

export default App;
